$(document).ready(function () {
    $('.kitchen-style-slider').slick({
        lazyLoad: 'ondemand',
        infinite: true,
        slidesToShow: 4,
        dots: true,
        appendDots: ".slider-dots",
        prevArrow: '.slider-control.prev',
        nextArrow: '.slider-control.next',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    autoplay: true
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    arrows: false
                }
            }
        ]
    });
    $('.kitchen-brands .brands').slick({
        lazyLoad: 'ondemand',
        infinite: true,
        autoplay: true,
        pauseOnHover: true,
        slidesToShow: 7,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 6
                }
            }
        ]
    });
});

// Header video
if ($('#sdk-video').length) {
    const mainVideo = $('#sdk-video');
    if ($(window).width() < 800) {
        mainVideo.append("<source type='video/mp4' src='/assets/video/video-sdk-mobile.mp4' />");
    } else {
        mainVideo.append("<source type='video/mp4' src='/assets/video/video-sdk-hq.mp4' />");
    }
}
